<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Closed Work Groups</h1>
        </v-col>
        <v-col>
          <v-text-field
            label="Search Work Groups"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
            v-model="searchTerm"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-btn depressed light :to="{ name: 'module-workgroup' }">
            Back to Work Groups
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="groups"
            no-data-text="No work groups found"
          >
            <template v-slot:item.name="{ item }">
              <router-link
                :to="{
                  name: 'module-group-individual',
                  params: { groupId: item.id },
                }"
                >{{ item.name }}</router-link
              >
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="
                      activateGroupDialog = true;
                      activateGroupData = item;
                    "
                  >
                    <v-icon small>mdi-account-check</v-icon>
                  </v-btn>
                </template>
                <span>Reopen Group</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="activateGroupDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Reopen Group</v-card-title>
        <v-card-text
          >Are you sure you want to reopen
          {{ activateGroupData ? activateGroupData.name : "" }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="activateGroupDialog = false"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="activateGroupLoading"
            @click="reactivateGroup()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      activateGroupDialog: false,
      activateGroupLoading: false,
      activateGroupData: null,
      breadcrumbs: [
        {
          text: "Workload",
          disabled: false,
          exact: true,
          to: {
            name: "module-workload",
            params: { id: this.$route.params.id },
          },
        },
        {
          text: "Work Groups",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Actions", value: "actions", align: "right" },
      ],
    };
  },

  computed: {
    groups() {
      let groups = this.$store.getters["workload/archivedGroups"];

      if (this.searchTerm !== "") {
        groups = groups.filter((g) => {
          const name = g.name.toLowerCase();
          return name.includes(this.searchTerm.toLowerCase());
        });
      }
      return groups;
    },
  },

  methods: {
    reactivateGroup: function () {
      const appId = this.$route.params.id;
      const groupId = this.activateGroupData.id;

      this.activateGroupLoading = true;

      this.$store
        .dispatch("workload/reopenGroup", {
          appId,
          groupId,
        })
        .then(() => {
          this.activateGroupData = null;
          this.activateGroupLoading = false;
          this.activateGroupDialog = false;
        });
    },
  },
};
</script>
